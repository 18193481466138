import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql} from 'gatsby'
import Particles from 'react-particles-js';

import Alfie from '../img/emotes/lab/alfie.png';
import Bunny from '../img/emotes/lab/bunny.png';
import Clara from '../img/emotes/lab/clara.png';
import Dwight from '../img/emotes/lab/dwight.png';
import Gab from '../img/emotes/lab/gab.png';
import Gax from '../img/emotes/lab/gax.png';
import Marco from '../img/emotes/lab/marco.png';
import Marilou from '../img/emotes/lab/marilou.png';
import Max from '../img/emotes/lab/max.png';
import Max_Rage from '../img/emotes/lab/max_rage.png';
import Michel from '../img/emotes/lab/michel.png';
import Mike from '../img/emotes/lab/mike.png';
import Mike_Reverse from '../img/emotes/lab/mike_reverse.png';
import Nala from '../img/emotes/lab/nala.png';
import Sab from '../img/emotes/lab/sab.png';
import Seb from '../img/emotes/lab/seb.png';
import Steph from '../img/emotes/lab/steph.png';
import Supacow from '../img/emotes/lab/supacow.png';
import Unicorn from '../img/emotes/lab/unicorn.png';
import Val from '../img/emotes/lab/val.png';

import Layout from '../components/Layout'

import scroll from '../components/scroll';


import BlogRollHomePage from '../components/BlogRollHomePage'
// import effentilogo from "../img/effenti.svg";
import valEaster from "../img/valEaster.png";
import yosound from "../img/yosound.m4a";

export const IndexPageTemplate = ({
        blocequipe,
        blocservice,
        bloclab,
        blocrecrutement
    }) => (
    <div id="homepage" className="page-ctn">
        {/* running the scroll animation */}
        {scroll(963)}
        <section className="bloc-equipe"
            style={{
                backgroundImage: `url(${
                    !!blocequipe.image.childImageSharp ? blocequipe.image.childImageSharp.fluid.src : blocequipe.image
                    })`
            }}>
            <div className="cover-ctn">
                <div className="container">
                    <div className="red-square">
                        <h1>
                            <span>Rendre<br/>les ti plus<br/>humaines.</span>
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section className="service-lab-ctn">
          <div className="container service-lab">
            <Link className="block lab" to="/lab-effenti">
            <Particles
                params={{
                    particles: {
                        "number": {
                            "value": 70,
                            "density": {
                                "enable": false,
                                "value_area": 800
                            }
                        },
                        "size": {
                            "value": 20,
                            "random": false,
                        },
                        "line_linked": {
                            "enable": false,
                        },
                        "move": {
                            "enable": true,
                            "speed": 1.5,
                            "direction": "bottom-right",
                            "random": true,
                            "straight": true,
                            "out_mode": "out",
                            "bounce": true,
                            "attract": {
                                "enable": false,
                                "rotateX": 600,
                                "rotateY": 1200
                            }
                        },
                        shape: {
                            type: 'images',
                            images: [
                                {src: Alfie, height: 128, width: 128},
                                {src: Bunny, height: 128, width: 128},
                                {src: Clara, height: 128, width: 128},
                                {src: Dwight, height: 128, width: 128},
                                {src: Gab, height: 128, width: 128},
                                {src: Gax, height: 128, width: 128},
                                {src: Marco, height: 128, width: 128},
                                {src: Marilou, height: 128, width: 128},
                                {src: Max, height: 128, width: 128},
                                {src: Max_Rage, height: 128, width: 128},
                                {src: Michel, height: 128, width: 128},
                                {src: Mike, height: 128, width: 128},
                                {src: Mike_Reverse, height: 128, width: 128},
                                {src: Nala, height: 128, width: 128},
                                {src: Sab, height: 128, width: 128},
                                {src: Seb, height: 128, width: 128},
                                {src: Steph, height: 128, width: 128},
                                {src: Supacow, height: 128, width: 128},
                                {src: Unicorn, height: 128, width: 128},
                                {src: Val, height: 128, width: 128},
                            ]
                        }
                    },
                    "retina_detect": true
                }}
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0'
                }} />
                <h2>{bloclab.titre}</h2>
                <div className="cover-ctn"></div>
            </Link>
            <Link className="block sc" to="/service-conseil">
            <Particles
                params={{
                    "particles": {
                        "number": {
                          "value": 75,
                          "density": {
                            "enable": false,
                            "value_area": 800
                          }
                        },
                        "color": {
                          "value": "#fd534a"
                        },
                        "shape": {
                          "type": "circle",
                          "stroke": {
                            "width": 0,
                            "color": "#000000"
                          },
                          "polygon": {
                            "nb_sides": 5
                          },
                        },
                        "opacity": {
                          "value": 1,
                          "random": false,
                          "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.1,
                            "sync": false
                          }
                        },
                        "size": {
                          "value": 5,
                          "random": true,
                          "anim": {
                            "enable": false,
                            "speed": 40,
                            "size_min": 0.1,
                            "sync": false
                          }
                        },
                        "line_linked": {
                          "enable": true,
                          "distance": 150,
                          "color": "#fd534a",
                          "opacity": 0.4,
                          "width": 1
                        },
                        "move": {
                          "enable": true,
                          "speed": 2,
                          "direction": "none",
                          "random": false,
                          "straight": false,
                          "out_mode": "out",
                          "bounce": false,
                          "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                          }
                        }
                      },
                      "retina_detect": true
                }}
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0'
                }} />
                <h2>{blocservice.titre}</h2>
                <div className="cover-ctn"></div>
            </Link>
          </div>
        </section>

        <section className="container join-us">
            <div className="bloc-recrutement"
            style={{
                backgroundImage: `url(${
                    !!blocrecrutement.image.childImageSharp ? blocrecrutement.image.childImageSharp.fluid.src : blocrecrutement.image
                    })`
            }}>
                <div className="cover-ctn">
                    <div className="container">
                        <h2 id="scrolled" className="homeAnim">
                            <span>{blocrecrutement.titre}</span>
                        </h2>
                        <div className="btn-ctn">
                            <Link className="btn is-transparent" to="/emplois">
                                {blocrecrutement.linktitle}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="container">
            <div className="blog-roll blogrollpage">
              <div className="blogroll-head">
                <h2>
                    Blogue
                </h2>
                <Link className="is-link" to="/blog">
                  Voir plus
                </Link>
              </div>

                <div className="blogroll-ctn">

                    <BlogRollHomePage/>
                </div>
            </div>
        </section>
        <div className="spacer-red triangle-red-left is-relative"></div>
        <div id="easter-egg">
            <audio id="yoSound" src={yosound}></audio>
            <img
                id="easter-img"
                className={""}
            src={valEaster}
            alt="Easter egg"/>
        </div>
    </div>

)

IndexPageTemplate.propTypes = {
    blocequipe: PropTypes.object,
    blocservice: PropTypes.object,
    bloclab: PropTypes.object,
    blocrecrutement: PropTypes.object
}


const IndexPage = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout>
            <IndexPageTemplate
                blocequipe={frontmatter.blocequipe}
                blocservice={frontmatter.blocservice}
                bloclab={frontmatter.bloclab}
                blocrecrutement={frontmatter.blocrecrutement}
            />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        blocequipe {
          titre
          image {
              childImageSharp {
                fluid(maxWidth: 1680, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        blocservice {
          titre
        }
        bloclab {
          titre
        }
        blocrecrutement {
          titre
          linktitle
          image {
              childImageSharp {
                fluid(maxWidth: 1680, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
      }
    }
  }
`


var keyword = '';
var timeout = null;

if(typeof window !== 'undefined') {



    window.document.onkeydown = function(e){
        e = e || window.event;
        keyword += e.key;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            if(keyword === 'yoyoyo'){
                var easterEgg = document.getElementById('easter-egg');
                var easterImg = document.getElementById('easter-img');
                if(easterEgg && easterImg){
                    addClass();
                }
                keyword = '';
            } else {
                keyword = '';
            }
        }, 2000);
    }

}


function addClass(){
    var easterEgg = document.getElementById('easter-egg');
    var easterImg = document.getElementById('easter-img');


    easterEgg.classList.add("visible");
    easterImg.classList.add("valAnim");
    document.getElementById('yoSound').play();
    setTimeout(function() {
        easterEgg('easter-egg').classList.remove("visible");
        easterImg.classList.remove("valAnim");
    }, 4000);
}

